import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/hayden/dev/fluidsolarthermal.com/web/src/components/layout-markdown.tsx";
import SEO from '../../components/seo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="About us" mdxType="SEO" />
    <h1 {...{
      "id": "our-process"
    }}>{`Our Process`}</h1>
    <h3 {...{
      "id": "1-the-initial-consultation"
    }}>{`1. THE INITIAL CONSULTATION`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/977d7ed4075001ad6aeca7079d140d03/f67cc/sofa.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAQFAf/EABQBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAamxmhbJIn//xAAZEAEBAQADAAAAAAAAAAAAAAABAgQAEBP/2gAIAQEAAQUC6eRotv0tXRY//8QAFxEBAAMAAAAAAAAAAAAAAAAAARARMf/aAAgBAwEBPwELyP/EABcRAQADAAAAAAAAAAAAAAAAAAABESH/2gAIAQIBAT8BnFP/xAAZEAADAAMAAAAAAAAAAAAAAAAAAREQITH/2gAIAQEABj8CzKdGtM//xAAaEAADAQADAAAAAAAAAAAAAAAAARExIWGx/9oACAEBAAE/IUiGikJH1gpHkdTgew//2gAMAwEAAgADAAAAEB/v/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQARIf/aAAgBAwEBPxB3kXOX/8QAFhEBAQEAAAAAAAAAAAAAAAAAABFR/9oACAECAQE/ENFv/8QAGRABAQEBAQEAAAAAAAAAAAAAAREAIWGx/9oACAEBAAE/EKI54wDB1xhAcbmAPDqsyFR8d//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "sofa",
            "title": "sofa",
            "src": "/static/977d7ed4075001ad6aeca7079d140d03/f67cc/sofa.jpg",
            "srcSet": ["/static/977d7ed4075001ad6aeca7079d140d03/c635c/sofa.jpg 500w", "/static/977d7ed4075001ad6aeca7079d140d03/f67cc/sofa.jpg 800w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`First, we need to get a feel for what you’re looking for. All you need to do is jump into our design studio and provide some specifications about the number of bedrooms, bathrooms and other spaces you would like to include in your final design. We will suggest our most appropriate design to meet your needs, and allow you to make further choices about your level of grid-dependency and exterior cladding. You can submit your request and add any additional comments. We’ll then email you to arrange a follow-up consultation.`}</p>
    <h3 {...{
      "id": "2-planning-and-preparation"
    }}>{`2. PLANNING AND PREPARATION`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/74fa95fc9e3088b82595b682fdda3116/f67cc/kitchen.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIEA//EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAFx84sIiP/EABwQAAICAgMAAAAAAAAAAAAAAAACAQMREhMiI//aAAgBAQABBQJUSSKq86wUeo/S7lY//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAHRAAAgIBBQAAAAAAAAAAAAAAAQIAERASIUFSgf/aAAgBAQAGPwIhr8jUWsYY7ijwY+nrj//EABsQAQACAwEBAAAAAAAAAAAAAAEAESExUUGB/9oACAEBAAE/IVAXuiNAy4SAFUP2UucyDS6dZgx5P//aAAwDAQACAAMAAAAQXz//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAgEBPxCH/8QAGhABAQEAAwEAAAAAAAAAAAAAAREAITFBUf/aAAgBAQABPxDuBJF89+ZFGsl5mPqQ7UaxMEFl49zgNBRvJlbgClp83//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "kitchen",
            "title": "kitchen",
            "src": "/static/74fa95fc9e3088b82595b682fdda3116/f67cc/kitchen.jpg",
            "srcSet": ["/static/74fa95fc9e3088b82595b682fdda3116/c635c/kitchen.jpg 500w", "/static/74fa95fc9e3088b82595b682fdda3116/f67cc/kitchen.jpg 800w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Once we’ve optimised the configuration for your project, we move to the planning stage. This may involve a virtual or actual site visit to ensure your design takes full advantage of the location you’ve chosen. A fee will apply for this site visit depending on your location.`}</p>
    <p>{`Then, we will work with you to secure development and town planning approval with a fixed price contract in place for personalised design, fabrication and delivery phases.`}</p>
    <p>{`While fabrication and assembly is underway you will need a builder on site to complete site works including footings, any ground works including retaining walls and drainage etc, as well as services which may include reticulated services such as water, sewer, power and telecommunications.`}</p>
    <h3 {...{
      "id": "3-fabrication"
    }}>{`3. FABRICATION`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/50c8a7dbb1e14f757f3424216a9e789c/f67cc/fabrication.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIFAQT/xAAWAQEBAQAAAAAAAAAAAAAAAAADAAL/2gAMAwEAAhADEAAAAcee5JZOYzf/xAAaEAADAAMBAAAAAAAAAAAAAAAAAQIDERIT/9oACAEBAAEFAox8KM2h1R7sVdDW3//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAECAQE/AUf/xAAbEAACAgMBAAAAAAAAAAAAAAAAMQERECGBkf/aAAgBAQAGPwKJvZ0UeiQqx//EABkQAQEBAQEBAAAAAAAAAAAAAAERACFBUf/aAAgBAQABPyEKK2T53O0J41PQwcOJ5+4H7W246J13/9oADAMBAAIAAwAAABC7/wD/xAAWEQEBAQAAAAAAAAAAAAAAAAARAAH/2gAIAQMBAT8QwIv/xAAXEQADAQAAAAAAAAAAAAAAAAABEBFB/9oACAECAQE/EIdT/8QAGhABAQEBAQEBAAAAAAAAAAAAAREAIUFRwf/aAAgBAQABPxAJl0HRUfmGa2j7l9J8dkZehWrbrwouKulMhVm//9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "fabrication",
            "title": "fabrication",
            "src": "/static/50c8a7dbb1e14f757f3424216a9e789c/f67cc/fabrication.jpg",
            "srcSet": ["/static/50c8a7dbb1e14f757f3424216a9e789c/c635c/fabrication.jpg 500w", "/static/50c8a7dbb1e14f757f3424216a9e789c/f67cc/fabrication.jpg 800w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`With design and planning approval and a timeline for your site works confirmed, we will commence the fabrication phase. With many elements of the modular design prefabricated, module fabrication is typically completed in around 14 days, depending on the number of dwellings already on order. If you’d like to see our manufacturing team in action, we can organise for you to pop in and have a look at the process.`}</p>
    <h3 {...{
      "id": "4-delivery-and-installation"
    }}>{`4. DELIVERY AND INSTALLATION`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/5d2ded2a1da8d34b0513e9b066a99203/f67cc/install.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMCBAX/xAAVAQEBAAAAAAAAAAAAAAAAAAACA//aAAwDAQACEAMQAAABZSVGdNEQBf/EABoQAAIDAQEAAAAAAAAAAAAAAAEDAAISEzP/2gAIAQEAAQUCJJO9Pl22o5Xr0M//xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAwEBPwFZ/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAR/9oACAECAQE/AQwsv//EABoQAAICAwAAAAAAAAAAAAAAAAABEFECETH/2gAIAQEABj8CWkY1RyFH/8QAGxAAAgMBAQEAAAAAAAAAAAAAAAERITFRYXH/2gAIAQEAAT8hVE3axColTBD8/WPOOoJN02WPd6f/2gAMAwEAAgADAAAAEKv/AP/EABYRAQEBAAAAAAAAAAAAAAAAAAEQEf/aAAgBAwEBPxAFwn//xAAXEQEAAwAAAAAAAAAAAAAAAAAAAREh/9oACAECAQE/ENSVn//EABwQAQADAAMBAQAAAAAAAAAAAAEAESExQWFx8P/aAAgBAQABPxDArbGoVpxU7OO/Yu7aZSDACqgqsHPvsaJCVU54hk0pH5U//9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "install",
            "title": "install",
            "src": "/static/5d2ded2a1da8d34b0513e9b066a99203/f67cc/install.jpg",
            "srcSet": ["/static/5d2ded2a1da8d34b0513e9b066a99203/c635c/install.jpg 500w", "/static/5d2ded2a1da8d34b0513e9b066a99203/f67cc/install.jpg 800w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Completed modules are transported to site and assembled in a few days to a prepared site. We will work with your builder to complete installation of the modules and connection of services in minimum time and with minimal on site trades.`}</p>
    <hr></hr>
    <p>{`Contact us about your next project`}</p>
    <p>{`127 Haydown Rd, Elizabeth Vale SA 5112, Australia`}</p>
    <p>{`enquiries@fluidsolarthermal.com`}</p>
    <p>{`+618 8440 7788
+61 414 450 215`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      